import clsx from 'clsx';
import { Container } from './Container';
import { Button } from './Button';
import { useState } from 'react';

function SwirlyDoodle(props) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 281 40"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
      />
    </svg>
  )
}

function CheckIcon({ className, ...props }) {
  return (
    <svg
      aria-hidden="true"
      className={clsx(
        'h-6 w-6 flex-none fill-current stroke-current',
        className,
      )}
      {...props}
    >
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle
        cx={12}
        cy={12}
        r={8.25}
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function Plan({
  name,
  price,
  description,
  href,
  features,
  featured = false,
  onHover,
  onHoverOut,
  isHovered,
}) {
  return (
    <section
      onMouseEnter={onHover}
      onMouseLeave={onHoverOut}
      className={clsx(
        'flex flex-col rounded-3xl px-6 sm:px-8 transition-colors duration-300',
        featured ? 'order-first py-8 lg:order-none' : 'lg:py-8',
        isHovered
          ? 'bg-teal-green text-white'
          : 'bg-transparent text-slate-800 hover:bg-teal-green hover:text-white'
      )}
    >
      <h3 className="mt-5 font-display text-3xl">{name}</h3>
      <p className="mt-2 text-xl">{description}</p>
      <p className="order-first font-display text-5xl font-light tracking-tight">
        {price}
      </p>
      <ul
        role="list"
        className={clsx(
          'order-last mt-10 flex flex-col gap-y-3 text-2xl',
          isHovered ? 'text-white' : 'text-slate-200'
        )}
      >
        {features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon className={isHovered ? 'text-white' : 'text-slate-400'} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <Button
        href={href}
        target="_blank"
        variant={featured ? 'outline' : 'outline'}
        className="mt-8 hover:bg-custom-gray/10 outline-teal-green"
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        Get started
      </Button>
    </section>
  );
}

export function Pricing() {
  // Highlight the middle plan by default (index 1)
  const [hoveredPlan, setHoveredPlan] = useState(1);

  const backendUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/api'
      : 'https://radiata.ai/api';

  const plans = [
    {
      name: 'Research Starter',
      price: '$50/scan/year',
      description: 'Ideal for small research groups and pilot projects.',
      href: `${backendUrl}/create-checkout-session/?quantity=1`,
      features: [
        'Process & host up to 99 scans annually',
        'Structural and functional MRI processing',
        'Analyze your + public data in MxD browser',
        'Apply AI/ML models from growing library with no code',
        'Download your processed data any time',
      ],
    },
    {
      featured: true,
      name: 'R&D Explorer',
      price: '$40/scan/year',
      description: 'Perfect for modern research projects.',
      href: `${backendUrl}/create-checkout-session/?quantity=100`,
      features: [
        'For 100-400 scans annually ($4K/year minimum)',
        'Save and share more models',
        'Expedited incorporation of desired public datasets',
        'Add additional users for fee',
      ],
    },
    {
      name: 'R&D Pro',
      price: '$25/scan/year',
      description:
        'Tailored for neurotech companies, medical centers, and clinical trials.',
      href: `${backendUrl}/create-checkout-session/?quantity=400`,
      features: [
        'For 400+ scans annually ($10K/year minimum)',
        'Request custom features built to specifications',
        'API for database access',
        'Enhanced customer support',
      ],
    },
  ];
    
    return (
      <section
        id="pricing"
        aria-label="Pricing"
        className="bg-green py-20 sm:py-32"
      >
        <Container>
          <div className="md:text-center">
            <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
              <span className="relative whitespace-nowrap">
                <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-kelly-green" />
                <span className="relative">Simple pricing,</span>
              </span>{' '}
              affordable and scalable for everyone.
            </h2>
            <p className="mt-4 text-xl text-slate-400">
              Maximize the value you derive from every scan you collect.
            </p>
          </div>
          <div className="-mx-4 mt-16 grid bg-dark-blue max-w-2xl text-white grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8 border-2 rounded-3xl">
          {plans.map((plan, index) => (
            <Plan
              key={plan.name}
              {...plan}
              onHover={() => setHoveredPlan(index)}
              onHoverOut={() => setHoveredPlan(null)}
              isHovered={hoveredPlan === index}
              isOtherHovered={
                hoveredPlan !== null && hoveredPlan !== index
              }
            />
          ))}
        {/* <Plan 
          name="Research Starter"
          price="$50/scan/year"
          description="Ideal for small research groups and pilot projects."
          href={`${backendUrl}/create-checkout-session/?quantity=1`}
          features={[
            'Process & host up to 99 scans annually',
            'Structural and functional MRI processing',
            'Analyze your + public data in MxD browser',
            'Apply AI/ML models from growing library with no code',
            'Download your processed data any time',
          ]}
        />
        <Plan
          featured
          name="R&D Explorer"
          price="$40/scan/year"
          description="Perfect for modern research projects."
          href={`${backendUrl}/create-checkout-session/?quantity=100`}
          features={[
            'For 100-400 scans annually ($4K/year minimum)',
            'Save and share more models',
            'Expedited incorporation of desired public datasets',
            'Add additional users for fee',
            
          ]}
        />
        <Plan
          name="R&D Pro"
          price="$25/scan/year"
          description="Tailored for neurotech companies, medical centers, and clinical trials."
          href={`${backendUrl}/create-checkout-session/?quantity=400`}
          features={[
            'For 400+ scans annually ($10K/year minimum)',
            'Request custom features built to specifications',
            'API for database access',
            'Enhanced customer support',
          ]}
        /> */}
      </div>
      </Container>
    </section>
  )
}
  