import { Container } from './Container'
import backgroundImage from './images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'Can I use the site for free?',
      answer:
        'Yes! All the main functionality is available for working with public datasets: viewing scans, running and saving models, browsing latent space.',
    },
    {
      question: 'How do I access shared datasets?',
      answer: 'For datasets are truly open, like OASIS-1 and 2,  we make them available for everyone (with all PHI removed). For shared datasets that require permissions to access, like ADNI, we continue to add them to our database. If you want access, contact us and after verifying you have permissions from the study owners, we make all permitted data available to you.',
    },
  ],
  [
    {
      question: 'Why is there a subscription fee for private data analysis?',
      answer: 'Our service includes image processing as the first step. The benefits for subscribing are: 1) Your data is live in the application and available for running models and browsing, 2) We host your raw and processed data securely in the cloud, 3) As new models and pipelines are added to our platform, we continually apply those to your subscribed data to extract new measures, and 4) As new datasets are added to our platform, we continually train new  models that will get more robust and powerful for extracting patterns from your data.',
    },
  ],
  [
    {
      question: 'Is the application FDA approved?',
      answer: 'Our platform is designated for "Research Use Only" (RUO) and does not require FDA approval (for now). It is intended to be used by researchers and academic institutions to advance neuroimaging biomarker discovery.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-gradient-to-br from-custom-gray to-dark-blue p-10"
    >
      {/* <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
        unoptimized
      /> */}
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
        </div>
        <ul
          role="list"
          className="list-none mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-2xl leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-xl text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
