import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { logout } from '../lib/allauth'
import Button from '../components/Button'


export default function Logout () {
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    logout().then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  if (response.content) {
    return <Navigate to='/' />
  }

  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray px-4">
      <div className="bg-custom-gray p-8 w-full max-w-md">
        <p className="text-center mb-6">Are you sure you want to logout?</p>
        <Button
          disabled={response.fetching}
          onClick={() => submit()}
          className="w-full bg-red-500 text-white py-1 px-4 rounded-md hover:bg-red-600 active:bg-red-700 disabled:bg-red-300"
        >
          {response.fetching ? 'Logging out...' : 'Logout'}
        </Button>
      </div>
    </div>
  )
}
