import { AuthContextProvider } from './auth'
import "./styles.css";
import * as d3 from "d3";
import { scaleQuantize } from "d3";
import { Legend, Swatches, joinPaths } from "./helpers"
import { useRef, useEffect, useState, useContext, useCallback, useMemo } from "react";
import { Niivue, Volume, NVImage } from "@niivue/niivue";
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey('d4694110492ca905d351a48c00fd05adTz05Nzg4NSxFPTE3NTc2MTMzMzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');
import axios from 'axios';
import { isNumber } from "@mui/x-data-grid/internals";
import URL from 'url-parse';
import { getCSRFToken } from './lib/django';
import { AuthContext } from './auth/AuthContext';
import React from 'react';
import ReactGA from 'react-ga4';
import Scatter from './Scatter'
import Stats from './Stats'
import Figures from './Figures'
import MRIViewer from './MRIViewer'
import Functional from './Functional'

// overriding console log to hide all without searching through them all
console.log = function() {};

const apiBaseUrl=process.env.REACT_APP_API_URL;


export default function App() {
  
  //const user = useUser();
  const [dataGlobal, setdataGlobal] = useState([]);
  const [filePath, setT1FilePath] = useState(null);
  const [fcMatrixFilePath, setFcMatrixFilePath] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [mainDataFile, setmainDataFile] = useState(null);
  const [numModels, setnumModels] = useState(0);
  const [statsModels, setStatsModels] = useState([]);
  //const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({'indices': [], 'colors': []});
  const [selectedSingleIndex, setSelectedSingleIndex] = useState(null);
  const [selectedGroupOpacity, setSelectedGroupOpacity] = useState({'indices': []});
  const [categoryColors, setCategoryColors]  = useState([]);
  const [selectedRegions, setSelectedRegions]  = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('clinical_diagnosis');
  const [brainRegionInfo, setBrainRegionInfo] = useState();
  const [fileName, setFileName] = useState(null); 
  const [selectedScatterX, setselectedScatterX] = useState('autoencoder_pc_1');
  const [selectedScatterY, setselectedScatterY] = useState('autoencoder_pc_2');
  const [isLoading, setIsLoading] = useState(false);
  const [dataGlobalColumnInfo, setDataGlobalColumnInfo] = useState([]);

  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const csrfToken = getCSRFToken();
    console.log('CSRF Token:', csrfToken);
    console.log('Request config:', {
      url: `${apiBaseUrl}/get_filtered_data/`,
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    const getSubjectData = () => {
      console.log('Fetching subject data...');
      axios.get(`${apiBaseUrl}/get_filtered_data/`, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log('Data fetched:', res.data);
        const processedData = res.data.data.map(row => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value === null ? 'NA' : value])
          );
        });
        setdataGlobal(processedData);
        setmainDataFile(res.data.file_name);
      })
      .catch((error) => {
        console.error('Error fetching or processing data:', error);
      });
    };

    getSubjectData();
  }, []);


const dataGlobalMap = useMemo(() => {
  return new Map(dataGlobal.map(item => [item.id, item]));
}, [dataGlobal]);
 
  // console.log('dataGlobal', dataGlobal)
  //console.log('mainDataFile', mainDataFile)
  
  useEffect(() => {
    if (selectedSingleIndex !== null && dataGlobalMap.has(selectedSingleIndex)) {
      const selectedItem = dataGlobalMap.get(selectedSingleIndex);
      if (selectedItem && selectedItem['processed_affine_t1_path']) {
        setT1FilePath(selectedItem['processed_affine_t1_path']);
        setFcMatrixFilePath(selectedItem['fc_matrix']);
        setSelectedData(selectedItem);
      }
    }
  }, [selectedSingleIndex, dataGlobalMap]);


  useEffect(() => {
    // if (dataGlobal.length > 0 && dataGlobalColumnInfo.length === 0) {
    if (dataGlobal.length > 0) {
      // Prespecified columns to be hidden
      const hiddenColumns = new Set([
        'in_w_control_sample',
        'raw_t1_path',
        'processed_affine_t1_path',
        'processed_smoothed_t1_path',
        'region_gm_path',
        't1_z_map_path',
        't1_w_map_path',
        'region_gm_z_path',
        'region_gm_w_path',
        'valid',
        'participant_id',
        'session_id',
        'scan_date',
        'user',
        'user_id',
        'unique_study',
        'unique_study_id',
        'UMAP_1',
        'UMAP_2',
        'field_strength',
        'race',
        'MMSE',
        'APOE',
        'CDR',
        'autoencoder_pc_3',
        'autoencoder_pc_4',
        'autoencoder_pc_5',
        'autoencoder_pc_6',
        'autoencoder_pc_7',
        'autoencoder_pc_8',
        'autoencoder_pc_9',
        'autoencoder_pc_10',
      ]);
  
      // Prespecified order of columns
      const columnSequenceOrder = [
        'id',
        'study',
        'age',
        'sex',
        'clinical_diagnosis',
        'handedness',
        'scan_site',
        'scanner_manufacturer',
        'scanner_model',
        'education_years',
        'CDRSB',
        'TIV',
        'IQR',
        'participant_id',
        'session_id',
        'scan_date',
        'autoencoder_pc_1',
        'autoencoder_pc_2',
        'autoencoder_pc_3',
        'autoencoder_pc_4',
        'autoencoder_pc_5',
        'autoencoder_pc_6',
        'autoencoder_pc_7',
        'autoencoder_pc_8',
        'autoencoder_pc_9',
        'autoencoder_pc_10',
        'UMAP_1',
        'UMAP_2',
        'atrophy_mean_z',
        'user',
        'unique_study',
        'in_w_control_sample',
        'race',
        'MMSE',
        'APOE',
        'CDR',
        'field_strength',
        'raw_t1_path',
        'processed_affine_t1_path',
        'processed_smoothed_t1_path',
        'region_gm_path',
        't1_z_map_path',
        't1_w_map_path',
        'region_gm_z_path',
        'region_gm_w_path',
        'valid',
      ];
  
      // Mapping from column name to sequence number
      const columnSequenceMap = {};
      columnSequenceOrder.forEach((colName, index) => {
        columnSequenceMap[colName] = index + 1; // Start sequence from 1
      });
  
      // Prespecified categorical and continuous columns
      const categoricalColumnsSet = new Set([
        'clinical_diagnosis',
        'handedness',
        'sex',
        'study',
        'scan_site',
        'scanner_manufacturer',
        'scanner_model',
        'field_strength',
        'race',
        'APOE',
      ]);
  
      const continuousColumnsSet = new Set([
        'age',
        'education_years',
        'TIV',
        'IQR',
        'CDRSB',
        'MMSE',
        'CDR',
        'UMAP_1',
        'UMAP_2',
        'atrophy_mean_z',
        'autoencoder_pc_1',
        'autoencoder_pc_2',
        'autoencoder_pc_3',
        'autoencoder_pc_4',
        'autoencoder_pc_5',
        'autoencoder_pc_6',
        'autoencoder_pc_7',
        'autoencoder_pc_8',
        'autoencoder_pc_9',
        'autoencoder_pc_10',
      ]);
  
      // Initialize columnInfo array
      const columnInfo = Object.keys(dataGlobal[0]).map((columnName) => {
        const visible = !hiddenColumns.has(columnName);
        let variableType = 'other';
        let dataType = 'string';
        let type = 'string';
        let valueOptions = [];
  
        if (columnName === 'id') {
          variableType = 'continuous';
          dataType = 'number';
          type = 'number';
        } else if (categoricalColumnsSet.has(columnName)) {
          variableType = 'categorical';
          dataType = 'string';
          type = 'singleSelect';
          valueOptions = Array.from(new Set(dataGlobal.map((item) => item[columnName])));
        } else if (continuousColumnsSet.has(columnName)) {
          variableType = 'continuous';
          dataType = 'number';
          type = 'number';
        } else {
          // Infer variableType based on data
          const sampleValues = dataGlobal.map((item) => item[columnName]);
          const isNumberColumn = sampleValues.every((value) => {
            if (value === null || value === 'NA') {
              return true;
            }
            const coercedNumber = parseFloat(value);
            return Number.isFinite(coercedNumber);
          });
          if (isNumberColumn) {
            variableType = 'continuous';
            dataType = 'number';
            type = 'number';
          } else {
            variableType = 'categorical';
            dataType = 'string';
            type = 'singleSelect';
            valueOptions = Array.from(new Set(sampleValues));
          }
        }
  
        const width = columnName === 'id' ? 58 : 75;
        const sequence = columnSequenceMap[columnName] || (Object.keys(columnSequenceMap).length + 1);
  
        return {
          name: columnName,
          dataType,
          visible,
          variableType,
          width,
          type,
          valueOptions,
          sequence,
        };
      });
  
      // Sort columnInfo by sequence number
      columnInfo.sort((a, b) => a.sequence - b.sequence);
  
      setDataGlobalColumnInfo(columnInfo);
    }
  }, [dataGlobal]);  


  // Update dataGlobalColumnInfo when statsModels changes
  useEffect(() => {
    if (dataGlobalColumnInfo.length > 0 && statsModels.length > 0) {
      const currentModel = statsModels[0];

      // Clone existing columnInfo to avoid mutating state directly
      let updatedColumnInfo = [...dataGlobalColumnInfo];

      if (currentModel.computedVariables && currentModel.computedVariables.length > 0) {
        // Determine the next available sequence number
        let existingSequences = updatedColumnInfo.map((col) => col.sequence || 0);
        let maxSequence = Math.max(...existingSequences);
        let sequenceNumber = maxSequence + 1;

        currentModel.computedVariables.forEach((variableName) => {
          // Check if variable is already in columnInfo
          if (!updatedColumnInfo.some((col) => col.name === variableName)) {
            let dataType = 'number';
            let variableType = 'continuous'; // Default variableType
            let type = 'number';
            let valueOptions = [];

            
            if (currentModel.type === 'Classification') {
              if (/class.*pred.*/i.test(variableName)) {
                console.log([variableName, 'categorical'])
                // Matches 'class*pred*' pattern
                variableType = 'categorical';
                dataType = 'string';
                type = 'singleSelect';
                // Include 'NA' as possible category
                valueOptions = Array.from(
                  new Set(dataGlobal.map((item) => item[variableName]))
                );
              } else if (/class.*prob.*/i.test(variableName)) {
                console.log([variableName, 'continuous'])
                // Matches 'class*prob*' pattern
                variableType = 'continuous';
                dataType = 'number';
                type = 'number';
              }
            } else if (currentModel.type === 'Regression') {
              variableType = 'continuous';
              dataType = 'number';
              type = 'number';
            } else {
              // For other model types, you might want to set variableType as 'other'
              variableType = 'other';
              dataType = 'string';
              type = 'string';
            }

            // Add the new column info
            updatedColumnInfo.push({
              name: variableName,
              dataType: dataType,
              visible: true,
              variableType: variableType,
              width: 75,
              type: type,
              valueOptions: valueOptions,
              sequence: sequenceNumber++,
            });
          }
        });
      }

      // Sort the updatedColumnInfo by sequence to maintain order
      updatedColumnInfo.sort((a, b) => a.sequence - b.sequence);

      setDataGlobalColumnInfo(updatedColumnInfo);
    }
  }, [statsModels]);


  // load brain region info
  useEffect(() => {
    const csrfToken = getCSRFToken();
    const fetchData = async () => {
      console.log('Fetching subject data...');
      axios.get(`${apiBaseUrl}/get_brain_regions/`, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        })
        .then((res) => {
          const brainRegionData = res.data.map(row => {
            return Object.fromEntries(
              Object.entries(row).map(([key, value]) => [key, value === null ? 'NA' : value])
            );
          });
          setBrainRegionInfo(brainRegionData);
        })
        .catch((error) => {
          console.error('Error fetching or processing data:', error);
        });
      };
      fetchData();
    }, []);


    // Google analytics
    useEffect(() => {
      ReactGA.initialize('G-KZJCNKT2RG');
      
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);


    return (
      <div className="font-rubik text-center flex flex-col flex-1 h-full min-h-0 overflow-hidden bg-gray-100">
        {/* Main Content */}
        <div className="flex-1 flex overflow-hidden min-h-0">
          
          {/* First Column */}
          <div className="w-1/3 p-2 flex flex-col gap-y-2 min-h-0 overflow-hidden">
            <div className="scatter flex-1 flex flex-col h-full overflow-y-auto border border-black rounded box-border bg-white">
              <Scatter
                dataGlobal={dataGlobal}
                setT1FilePath={setT1FilePath}
                setFcMatrixFilePath={setFcMatrixFilePath}   
                setSelectedData={setSelectedData}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                selectedSingleIndex={selectedSingleIndex}
                setSelectedSingleIndex={setSelectedSingleIndex}
                categoryColors={categoryColors}
                setCategoryColors={setCategoryColors}
                statsModels={statsModels}
                selectedGroupOpacity={selectedGroupOpacity}
                setSelectedGroupOpacity={setSelectedGroupOpacity}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                selectedScatterX={selectedScatterX}
                setselectedScatterX={setselectedScatterX}
                selectedScatterY={selectedScatterY}
                setselectedScatterY={setselectedScatterY}
                dataGlobalColumnInfo={dataGlobalColumnInfo}
              />
            </div>
    
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto border border-black rounded box-border bg-white">
              <Stats
                dataGlobal={dataGlobal}
                setdataGlobal={setdataGlobal}
                mainDataFile={mainDataFile}
                setmainDataFile={setmainDataFile}
                numModels={numModels}
                setnumModels={setnumModels}
                setStatsModels={setStatsModels}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                setSelectedSingleIndex={setSelectedSingleIndex}
                auth={auth}
                setIsLoading={setIsLoading}
                dataGlobalColumnInfo={dataGlobalColumnInfo}
                brainRegionInfo={brainRegionInfo}
                selectedRegions={selectedRegions}
                setSelectedRegions={setSelectedRegions}
              />
            </div>
          </div>
          
          {/* Second Column */}
          <div className="w-1/3 p-2 flex flex-col gap-y-2 min-h-0 overflow-hidden">
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto border border-black rounded box-border bg-white">
              <Figures
                dataGlobal={dataGlobal}
                selectedData={selectedData}
                statsModels={statsModels}
                setSelectedGroup={setSelectedGroup}
                selectedSingleIndex={selectedSingleIndex}
                setSelectedSingleIndex={setSelectedSingleIndex}
                categoryColors={categoryColors}
                selectedRegions={selectedRegions}
                setSelectedRegions={setSelectedRegions}
                selectedGroup={selectedGroup}
                brainRegionInfo={brainRegionInfo}
                isLoading={isLoading}
              />
            </div>
          </div>
          
          {/* Third Column */}
          <div className="w-1/3 p-2 flex flex-col gap-y-2 min-h-0 overflow-hidden">
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto border border-black rounded box-border bg-white">
              <MRIViewer
                dataGlobal={dataGlobal}
                selectedData={selectedData}
                statsModels={statsModels}
                selectedRegions={selectedRegions}
                setSelectedRegions={setSelectedRegions}
                brainRegionInfo={brainRegionInfo}
                setBrainRegionInfo={setBrainRegionInfo}
                selectedScatterX={selectedScatterX}
                selectedScatterY={selectedScatterY}
              />
            </div>
            
            {/* Optional Functional component */}
            {/*
              <div className="flex-1 flex flex-col min-h-0 overflow-hidden border border-black rounded box-border bg-white">
              Functional component
            </div>
            */}
          </div>
          
        </div>
      </div>
    );    
    }