import { useRef, useEffect, useState, useMemo } from "react";
import * as d3 from "d3";
import useInteractiveLegend from './UseInteractiveLegend'

function Scatter({dataGlobal, setT1FilePath, setFcMatrixFilePath, setSelectedData, selectedGroup, setSelectedGroup, selectedSingleIndex, setSelectedSingleIndex, categoryColors, setCategoryColors, statsModels, selectedGroupOpacity, setSelectedGroupOpacity, selectedColumn, setSelectedColumn, selectedScatterX, setselectedScatterX, selectedScatterY, setselectedScatterY, dataGlobalColumnInfo }) {
    const d3Container = useRef();
    const svg = d3.select(d3Container.current);
    // svg.selectAll("g").remove();
    // const [categoricalColumns, setCategoricalColumns] = useState([]);
    // const [continuousColumns, setContinuousColumns] = useState([]);
    const [columnBinNames, setColumnBinNames] = useState({});
    const [categoryBins, setCategoryBins] = useState({});
    const [scatterX, setScatterX] = useState(["autoencoder_pc_1", "autoencoder_pc_2", "autoencoder_pc_3", "autoencoder_pc_4", "autoencoder_pc_5", "autoencoder_pc_6", "autoencoder_pc_7", "autoencoder_pc_8", "autoencoder_pc_9", "autoencoder_pc_10"]);
    const [scatterY, setScatterY] = useState(["autoencoder_pc_1", "autoencoder_pc_2", "autoencoder_pc_3", "autoencoder_pc_4", "autoencoder_pc_5", "autoencoder_pc_6", "autoencoder_pc_7", "autoencoder_pc_8", "autoencoder_pc_9", "autoencoder_pc_10"]);
    // const [allColumns, setAllColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    let selectedCircle = null;
    const numberBins = 6;
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, margins: {}, legendWidth: 0 });
    const [columnType, setColumnType] = useState('');
    const [tooltip, setTooltip] = useState(null);
    const [tooltipStyles, setTooltipStyles] = useState({});
  
    // Function to validate UMAP data
    const validateUMAPData = (data) => {
      return data.every(item => 
        typeof item.UMAP_1 === 'number' && !isNaN(item.UMAP_1) &&
        typeof item.UMAP_2 === 'number' && !isNaN(item.UMAP_2)
      );
    };


    useEffect(() => {
      if (!dataGlobal || dataGlobal.length === 0) {
        setLoading(true);
        return;
      }
  
      // Validate the data each time it changes
      if (!validateUMAPData(dataGlobal)) {
        //console.error('Invalid UMAP data: UMAP_1 or UMAP_2 contains non-numeric values.');
        setLoading(false);
        return; // Exit if data is not valid
      }
  
      // Proceed to render the scatter plot
      setLoading(false);
      // const svg = d3.select(d3Container.current);
      svg.selectAll("g").remove(); // Clear previous contents
    }, [dataGlobal]);


    // Compute categoricalColumns using useMemo
    const categoricalColumns = useMemo(() => {
      if (dataGlobalColumnInfo && dataGlobalColumnInfo.length > 0) {
        return dataGlobalColumnInfo
          .filter((col) => col.visible && col.variableType === 'categorical')
          .sort((a, b) => a.sequence - b.sequence)
          .map((col) => col.name);
      }
      return [];
    }, [dataGlobalColumnInfo]);
    
    // Compute continuousColumns using useMemo
    const continuousColumns = useMemo(() => {
      if (dataGlobalColumnInfo && dataGlobalColumnInfo.length > 0) {
        return dataGlobalColumnInfo
          .filter((col) => col.visible && col.variableType === 'continuous')
          .sort((a, b) => a.sequence - b.sequence)
          .map((col) => col.name);
      }
      return [];
    }, [dataGlobalColumnInfo]);
    
    // Compute allColumns using useMemo
    const allColumns = useMemo(() => {
      if (dataGlobalColumnInfo && dataGlobalColumnInfo.length > 0) {
        return dataGlobalColumnInfo
          .filter((col) => col.visible)
          .sort((a, b) => a.sequence - b.sequence)
          .map((col) => col.name);
      }
      return [];
    }, [dataGlobalColumnInfo]);


    // Initialize columnBinNames, categoryColors, and categoryBins
    useEffect(() => {
      if (
        !dataGlobal || 
        dataGlobal.length === 0 || 
        !selectedColumn || 
        categoricalColumns.length === 0 ||
        continuousColumns.length === 0
      ) return;

      let newCategoryColors = {};
      let newColumnBinNames = {};
      let newCategoryBins = {};
    
      // Handle selectedColumn
      if (categoricalColumns.includes(selectedColumn)) {
        // Handle categorical column
        let categories = Array.from(new Set(dataGlobal.map((d) => d[selectedColumn])));
    
        // Move 'NA' to the end
        const index = categories.indexOf('NA');
        if (index !== -1) {
          categories.splice(index, 1);
          categories.sort();
          categories.push('NA');
        } else {
          categories.sort();
        }
    
        newColumnBinNames[selectedColumn] = categories;
    
        // Define color scheme
        let colorScheme = categories.includes('NA')
          ? [...d3.schemeCategory10.slice(0, categories.length - 1), 'gray']
          : d3.schemeCategory10;
    
        newCategoryColors[selectedColumn] = d3.scaleOrdinal()
          .domain(categories)
          .range(colorScheme);
    
      } else if (continuousColumns.includes(selectedColumn)) {
        console.log('HI3')
        // Handle continuous column
        let dataColumn = dataGlobal.map((d) => d[selectedColumn]);
        let naPresent = dataColumn.includes('NA');
        let continuousValues = dataColumn.filter((d) => d !== 'NA').map(Number);
        let [minVal, maxVal] = d3.extent(continuousValues);
    
        if (minVal === undefined || maxVal === undefined) {
          minVal = 0;
          maxVal = 0;
        }
    
        const binScale = d3.scaleQuantize()
          .domain([minVal, maxVal])
          .range(d3.range(0, numberBins));
    
        newCategoryBins[selectedColumn] = binScale;
    
        newCategoryColors[selectedColumn] = d3.scaleLinear()
          .domain([minVal, (minVal + maxVal) / 2, maxVal])
          .range(['blue', '#d3d3d3', 'red'])
          .interpolate(d3.interpolateRgb)
          .unknown('gray');
    
        let binNames = binScale.range().map((value) => {
          const [min, max] = binScale.invertExtent(value);
          return `${min.toFixed(1)} - ${max.toFixed(1)}`;
        });
    
        if (naPresent) {
          binNames.push('NA');
        }
    
        binNames.sort((a, b) => {
          if (a === 'NA') return 1;
          if (b === 'NA') return -1;
          return parseFloat(a) - parseFloat(b);
        });

        newColumnBinNames[selectedColumn] = binNames;
      } else {
        console.warn(`Selected column ${selectedColumn} is not in categorical or continuous columns.`);
        return;
      }

      // Update state with new mappings
      setCategoryColors((prev) => ({ ...prev, ...newCategoryColors }));
      setColumnBinNames((prev) => ({ ...prev, ...newColumnBinNames }));
      setCategoryBins((prev) => ({ ...prev, ...newCategoryBins }));

    }, [dataGlobal, selectedColumn]);


     // Main scatterplot setup
    useEffect(() => {
      if (
        dataGlobal &&
        // Object.values(categoryColors).some(colorScale => typeof colorScale === 'function') &&
        categoryColors.hasOwnProperty(selectedColumn)
      ) {
        setLoading(false)

        const tooltip = d3.select("body").append("div")	
          .attr("class", "tooltip")				
          .style("opacity", 0);

        let currentColumnType;
        categoricalColumns.includes(selectedColumn) ? currentColumnType = 'categorical' : currentColumnType = 'continuous';
        setColumnType(currentColumnType);
        
        // Remove previous points to prevent duplicates after re-render
        svg.selectAll("g").remove()

        // Get dimensions
        const scatterDiv = document.getElementsByClassName('scatter')[0];
        const totalWidth = scatterDiv.clientWidth * 0.95;
        const totalHeight = scatterDiv.clientHeight * 0.75;

        // Define legend and scatterplot widths
        const legendWidth = totalWidth * 0.1;
        const scatterWidth = totalWidth * 0.9;

        // Margins
        const margins = {
          top: totalHeight * 0.05,
          right: totalWidth * 0.01,
          bottom: totalHeight * 0.1,
          left: totalWidth * 0.05,
        };

        // Update dimensions state
        setDimensions({ width: totalWidth, height: totalHeight, margins, legendWidth: legendWidth });

        // Set SVG dimensions
        svg.attr("viewBox", `0 0 ${totalWidth} ${totalHeight}`);

        // Scales
        const xScale = d3.scaleLinear()
          .domain(d3.extent(dataGlobal, d => d[selectedScatterX])).nice()
          .range([legendWidth + margins.left, totalWidth - margins.right]);

        const yScale = d3.scaleLinear()
          .domain(d3.extent(dataGlobal, d => d[selectedScatterY])).nice()
          .range([totalHeight - margins.bottom, margins.top]);

        // X Axis
        const xAxis = svg.append("g")
          .attr("transform", `translate(0, ${totalHeight - margins.bottom})`)
          .attr('stroke-width', 2)
          .call(d3.axisBottom(xScale));

        xAxis.append("text")
          .attr("class", "axis-label")
          .attr("y", margins.bottom - 5)
          .attr("x", (legendWidth + margins.left + totalWidth - margins.right) / 2)
          .attr("fill", "#000")
          .attr("text-anchor", "middle")
          .style("font-size", "14px")
          .text("Dimension 1");

        // Y Axis
        const yAxis = svg.append("g")
          .attr("transform", `translate(${legendWidth + margins.left},0)`)
          .attr('stroke-width', 2)
          .call(d3.axisLeft(yScale));

        yAxis.append("text")
          .attr("class", "axis-label")
          .attr("y", -margins.left)
          .attr("x", -(totalHeight / 2))
          .attr("fill", "#000")
          .attr("text-anchor", "middle")
          .attr("transform", "rotate(-90)")
          .style("font-size", "14px")
          .text("Dimension 2");

        // Get current category color mapping and bins
        let currentCategoryColor = categoryColors[selectedColumn];
        let currentCategoryBins;
        if (continuousColumns.includes(selectedColumn)) {
          currentCategoryBins = categoryBins[selectedColumn];
        }

        const scatterCircles = svg.append("g")
          .selectAll("circle.scatter-circle")
          .data(dataGlobal)
          .join("circle")
          .attr("class", "scatter-circle")
          .attr("transform", d => {
            const x = xScale(d[selectedScatterX]);
            const y = yScale(d[selectedScatterY]);
        
            // Check if x and y are numbers and not NaN (not-a-number)
            if (typeof x === "number" && !isNaN(x) && typeof y === "number" && !isNaN(y)) {
              return `translate(${x},${y})`;
            } else {
              // Provide a default position or handle error
              return "translate(0,0)";  // Default to origin if there's an issue
            }
          })
          .attr("r", 3)
          .attr("fill", d => {
            if (d[selectedColumn] === 'NA') {
              return 'gray';
            } else {
              return currentCategoryColor(d[selectedColumn]);
            }
          })
          .style("cursor", "pointer")
          .attr("stroke-width", function(d,i) {
            if (selectedSingleIndex === d.id || selectedGroup.indices.includes(d.id)) {
              //console.log(`selectedSingleIndex: ${selectedSingleIndex}, d.id: ${d.id}, selectedGroup.indices: ${selectedGroup.indices}`)
              return 4;
            } else {
              return 0;
            }
            return d3.select(this).attr('stroke-width');
          })
          .attr("stroke", function(d,i) {
            if (selectedSingleIndex === d.id) {
              d3.select(this)
                .raise();
              return "red";
            } else if (selectedGroup.indices.includes(d.id)) {
              return selectedGroup.colors[selectedGroup.indices.indexOf(d.id)];
              //return "lime";
            } else {
              return 'black';
            }
          })
          .attr("class", function(d,i) {
            let classString = 'scatter-circle';
            if (currentColumnType === 'continuous') {
              if (d[selectedColumn] === 'NA') {
                classString += ` bin${numberBins}`;
              } else {
                classString += ` bin${currentCategoryBins(d[selectedColumn])}`;
              }
            } else {
              classString += ` ${d[selectedColumn]}`;
            }
            if (selectedSingleIndex === d.id || (selectedGroup.indices && selectedGroup.indices.includes(d.id)) || selectedGroupOpacity.indices.includes(d.id)) {
              classString += ' selected-point-group';
            };
            return classString;
          })
          .style("opacity", function(d, i) {
            //console.log(selectedGroupOpacity.indices)
            if (selectedGroupOpacity.indices.length !== 0) {
              if (selectedGroupOpacity.indices.includes(d.id)) {
                return 1;
              } else {
                return 0.05;
              } 
            } else {
              return 1;
            }
          })
          .on("mouseover", function(e,d) {
            d3.select(this)
              .attr("stroke", "black")
              .attr("stroke-width", 4);
            d3.select(this)
            tooltip.transition()
              .duration(200)		
              .style("opacity", 1)
              .style("display", "block");
              let html = 
              `[Click to view scan]<br/>
              Age: ${Number.isFinite(d.age) ? d.age.toFixed(2) : 'NA'}<br/>
              Sex: ${d.sex}<br/>
              Clinical dx: ${d.clinical_diagnosis}<br/>
              Image quality: ${Number.isFinite(d.IQR) ? d.IQR.toFixed(2) : 'NA'}<br/>
              Mean atrophy: Z=${Number.isFinite(d.atrophy_mean_z) ? d.atrophy_mean_z.toFixed(2) : 'NA'}<br/>
              Study: ${d.study}<br/>
              Participant ID: ${d.participant_id}<br/>
              Session ID: ${d.session_id}<br/>
              Scan ID: ${d.id}<br/>`;
            
              for(let prop in d) {
                if(prop.startsWith('class_') && prop.includes('_prob_model') && d[prop] != 'NA') {
                  html += `${prop}: ${(d[prop]*100).toFixed(0)}%<br/>`;
                }
                if(prop.startsWith('class_pred_model') && d[prop] != 'NA') {
                  html += `${prop}: ${d[prop]}<br/>`;
                }
                if(prop.includes('_prediction_') && d[prop] != 'NA') {
                  html += `${prop}: ${d[prop].toFixed(2)}<br/>`;
                }
                if(prop.includes('_residuals_') && d[prop] != 'NA') {
                  html += `${prop}: ${d[prop].toFixed(2)}<br/>`;
                }
              }
            
              tooltip.html(html)
                .style("left", (e.clientX + 5) + "px")
                .style("top", (e.clientY + 5) + "px");
          })
          .on("mouseout", function(e,d) {
            tooltip.transition()		
              .duration(200)
              .style("opacity", 0)
              .style("display", "none");
            if (d.id !== selectedSingleIndex && !selectedGroup.indices.includes(d.id)) {
                d3.select(this)
                  .attr("stroke", null)
                  .attr("stroke-width", null);
            } else if (d.id == selectedSingleIndex) {
                d3.select(this)
                  .attr("stroke", "red")
                  .attr("stroke-width", 4);
              } else if (selectedGroup.indices.includes(d.id)) {
                d3.select(this)
                  .attr("stroke", selectedGroup.colors[selectedGroup.indices.indexOf(d.id)])
                  //return selectedGroup.colors[selectedGroup.indices.indexOf(d.id)];
                  .attr("stroke-width", 4);
            }
          })
          .on("click", function(event, d) {
            const index = d.id;
            if (selectedSingleIndex === index) {
              // If the clicked datapoint is already selected, unselect it
              setSelectedSingleIndex(null);
            } else {
              // Otherwise, select the clicked datapoint
              setSelectedSingleIndex(index);
            }
          });
        
        // Raise selected points to foreground
        svg.selectAll('.selected-point-group').raise();

        return () => {
          tooltip.remove();
        };
      }
    }, [dataGlobal, selectedColumn, selectedSingleIndex, selectedGroup, selectedGroupOpacity, selectedScatterX, selectedScatterY, categoryColors, categoryBins]);


    // Interactive legend
    const [legendSelection, setLegendSelection] = useState({'column': selectedColumn, 'selection': ''});
    useEffect(() => {
      if (
        !columnBinNames || 
        Object.keys(columnBinNames).length === 0 ||
        !columnBinNames[selectedColumn] ||
        dimensions.width === 0
      ) {
        return;
      }

      let categories = columnBinNames[selectedColumn];
      let legendColor = categoryColors[selectedColumn];
      let columnType = (dataGlobalColumnInfo.find(column => column.name === selectedColumn) || {}).variableType || null;
      let svgType = 'scatter';

      // if selected column changes, reset legend state
      if (selectedColumn!=legendSelection.column) {
        setLegendSelection({'column': selectedColumn, 'selection': ''})
        setSelectedGroupOpacity({'indices': []});
      }
      const width = dimensions.width;
      const height = dimensions.height;
      const margins = dimensions.margins;
      const legendWidth = dimensions.legendWidth;

      // svgType is one of 'scatter', 'roc'
      // columnType is one of 'continuous', 'categorical'
      const legendContainer = svg.append('g')
        .attr('class', 'scatterLegend')
        .attr('transform', (d, i) => svgType === 'scatter' ? 
              `translate(${margins.left}, ${margins.top})` :
              `translate(${margins.left + ((width-margins.left-margins.right)*0.7)}, ${margins.top + ((height-margins.top-margins.bottom)*0.55)})`)

      // Legend Box (adjust width and height as needed)
      const legendBox = legendContainer.append('rect')
        .attr('x', -10)
        .attr('y', -10)
        .attr('width', legendWidth)
        .attr('height', 16 *  categories.length)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('rx', 5)
        .attr('ry', 5)
        .style("opacity", 0.9);

      const scatterLegend = legendContainer.selectAll('.legendEntry')
        .data(categories)
        .enter()
        .append('g') 
        .attr('transform', (d, i) => `translate(0, ${i * 15})`)
        .each(function(d, i) {
          d3.select(this).on('click', e => handleClick(e, d, i));
        });

      // Get current category color mapping and bins
      let currentCategoryColor = categoryColors[selectedColumn];
      let currentCategoryBins;
      if (continuousColumns.includes(selectedColumn)) {
        currentCategoryBins = categoryBins[selectedColumn];
      }

      const legendCircles = scatterLegend.append('circle')
        .attr('r', 4)
        .attr('fill', columnType === 'continuous' ? 
          ((d, i) => {
            const [minValue, maxValue] = currentCategoryBins.invertExtent(i);
            const binAvg = (minValue + maxValue) / 2;
            return legendColor(binAvg);
          }) : 
          (d => legendColor(d)))
        .attr('class', function(d,i) {
          let classString;
          columnType === 'continuous' ? 
          classString = `legend-circle bin${i}` :
          classString = `legend-circle ${d}`
          return classString;
        })
        .style('cursor', 'pointer')
        .style('opacity', function(d,i) {
          let opacity;
          ((legendSelection.selection === `${d}`) || (legendSelection.selection === '')) ?
            opacity = 1 :
            opacity = 0.05;
          return opacity;
        });
  
      const legendTexts = scatterLegend.append('text')
        .attr('x', 10)
        .attr('y', 5)
        .attr('class', function(d,i) {
          let classString = 'legend-text';
          columnType === 'continuous' ? 
          classString += ` bin${i}` :
          classString += ` ${d}`
          return classString;
        })
        .style("cursor", "pointer")
        .style('font-size', '12px')
        .style('opacity', function(d,i) {
          let opacity;
          ((legendSelection.selection === `${d}`) || (legendSelection.selection === '')) ?
            opacity = 1 :
            opacity = 0.05;
          return opacity;
        })
        .text(d => d);
  
      let maxWidth = 0;
      legendContainer.selectAll('text').each(function() {
        maxWidth = Math.max(this.getBBox().width, maxWidth);
      });
      maxWidth += 25;
  
      legendBox.attr('width', maxWidth);
  
      function handleClick(event, categoryName, i) {
        let isSelectedCategory;
        let currentLegendSelection;
        let currentScatterIndices;
        // get indices for all scatter circles with current category
        columnType === 'continuous' ?
          isSelectedCategory = svg.selectAll(`.bin${i}`) :
          isSelectedCategory = svg.selectAll(`.${categoryName}`);
        const allElements = svg.selectAll(".roc-circle, .roc-line, .legend-circle, .legend-text");
        const nonSelectedCategories = allElements.filter((_,i,node) => !isSelectedCategory.nodes().includes(node[i]));
        isSelectedCategory.raise();
  
        // if clicked legend entry is already selected, unselect it
        if (categoryName === legendSelection.selection) {
          currentLegendSelection = {'column': selectedColumn, 'selection': ''};
          currentScatterIndices = [];
          if (svgType === 'roc') {
            allElements.style('opacity', 1);
          }
        } else { // otherwise, select clicked legend entry
          currentLegendSelection = categoryName;
          currentLegendSelection = {'column': selectedColumn, 'selection': categoryName};
          currentScatterIndices = isSelectedCategory.data().map(d => d.id);
          currentScatterIndices = currentScatterIndices.filter(item => !isNaN(item));
          if (svgType === 'roc') {
            isSelectedCategory.style('opacity', 1);
            nonSelectedCategories.style('opacity', 0.05);
          }
        }
        setLegendSelection(currentLegendSelection);
        // 'scatter' updates setSelectedGroupOpacity, 'roc' does not
        if (svgType === 'scatter') {
          setSelectedGroupOpacity({ 'indices': currentScatterIndices });
        }
      }
    },[selectedColumn, legendSelection, dataGlobal, columnBinNames, dimensions]);

  
    const tooltipText = [
      <span >
      <div style={{ fontWeight: 700 }}>Scan display color</div>
      Color scans based on demographic variables (age, sex, etc.), study variables, or model-derived measures like predicted age or diagnosis.
      <br/>
      Click the legend to highlight a group, based on category or bin.
    </span>,
      <span >
      <div style={{ fontWeight: 700 }}>Choose a variable</div>
      <div style={{ fontWeight: 700 }}>autoencoder_pc:</div>
      Scans are plotted based on an autoencoder, which compresses all the information from the T1 MRI images into a small number of dimensions. These dimensions capture the main sources of variation in the scans related to brain shape and size differences, disease patterns, and MRI scanner differences. The dimensions are sorted by how much variance they explain in the scans.
    </span>,
    ];
  
    const handleTooltipOver = (text) => (event) => {
      const rect = event.currentTarget.getBoundingClientRect();
      setTooltipStyles({ left: rect.x + rect.width + 2, top: rect.y });
      setTooltip(text);
    };
  
    const handleTooltipOut = () => {
      setTooltip("");
    };

    if (loading) {
      return <div>Loading...</div>; // Show loading state while data is not ready
    }

  
    return (
      <>
        <h1 className="font-bold m-0 text-2xl p-3">Latent Space [Brain Structure]</h1>
          <div className="mb-2 text-sm  border-gray-500">
            Color by:
            <select
              className="ml-2 border border-gray-500 rounded col-auto text-sm"
              onChange={(event) => setSelectedColumn(event.target.value)}
              value={selectedColumn}
            >
              {allColumns.map((column) => (
                <option key={column} value={column}>
                  {column}
                </option>
              ))}
            </select>
            
            <div
            className="latent-tooltip hover:bg-gray-200"
            style={{ ...tooltipStyles, opacity: tooltip ? 1 : 0 }}
          >
            {tooltip}
          </div>
          <img
            src="/images/info.png"
            alt="info"
            className="inline-block align-middle ml-1"
            height="16"
            width="16"
            style={{ verticalAlign: "middle", marginLeft: "5px" }}
            onMouseOver={(event) => {
              const rect = event.currentTarget.getBoundingClientRect();
              setTooltipStyles({ left: rect.x + rect.width, top: rect.y });
              setTooltip(tooltipText[0]);
            }}
            onMouseOut={() => setTooltip("")}
          />

          </div>
          <div className="mb-2 text-sm">
            Dim 1:
            <select
              className="ml-1 border border-gray-500 rounded col-auto text-sm"
              onChange={(event) => setselectedScatterX(event.target.value)}
              value={selectedScatterX}
            >
              {scatterX.map((currentX) => (
                <option key={currentX} value={currentX}>
                  {currentX}
                </option>
              ))}
            </select>

            <span className="ml-4 text-sm">Dim 2:</span>
            <select
              className="ml-1 border border-gray-500 rounded col-auto text-sm"
              onChange={(event) => setselectedScatterY(event.target.value)}
              value={selectedScatterY}
            >
              {scatterY.map((currentY) => (
                <option key={currentY} value={currentY}>
                  {currentY}
                </option>
              ))}
            </select>

            <div
            className="latent-tooltip hover:bg-gray-200"
            style={{ ...tooltipStyles, opacity: tooltip ? 1 : 0 }}
          >
            {tooltip}
          </div>
          <img
            src="/images/info.png"
            alt="info"
            className="inline-block align-middle ml-1"
            height="16"
            width="16"
            style={{ verticalAlign: "middle", marginLeft: "5px" }}
            onMouseOver={(event) => {
              const rect = event.currentTarget.getBoundingClientRect();
              setTooltipStyles({ left: rect.x + rect.width, top: rect.y });
              setTooltip(tooltipText[1]);
            }}
            onMouseOut={() => setTooltip("")}
          />

          </div>
          {/* <svg className="mb-0 mt-0 mr-3 ml-0" ref={d3Container} /> */}
          {/* <svg className="flex-1 w-full h-full min-h-0" ref={d3Container} /> */}
          <div className="flex-1 overflow-hidden">
            <svg className="w-full h-full" ref={d3Container} />
          </div>
      </>
    );
  }
  
  export default Scatter;