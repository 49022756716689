// VerifyEmail.jsx
import { useState } from 'react'
import { useLoaderData, Navigate } from 'react-router-dom'
import { getEmailVerification, verifyEmail } from '../lib/allauth'
import Button from '../components/Button'

export async function loader({ params }) {
  const key = params.key
  const resp = await getEmailVerification(key)
  return { key, verification: resp }
}

export default function VerifyEmail() {
  const { key, verification } = useLoaderData()
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit() {
    setResponse({ ...response, fetching: true })
    verifyEmail(key)
      .then((content) => {
        setResponse((r) => ({ ...r, content }))
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .finally(() => {
        setResponse((r) => ({ ...r, fetching: false }))
      })
  }

  if ([200, 401].includes(response.content?.status)) {
    return <Navigate to="/account/myaccount/email" />
  }

  let body = null
  if (verification.status === 200) {
    body = (
      <>
        <p className="text-black mb-4">
          Please confirm that{' '}
          <a
            href={`mailto:${verification.data.email}`}
            className="text-teal-green/80 underline hover:text-teal-green/90 active:text-teal-green"
          >
            {verification.data.email}
          </a>{' '}
          is an email address for user{' '}
          <span className="font-semibold">{verification.data.user.str}</span>.
        </p>
        <Button
          disabled={response.fetching}
          onClick={submit}
          className="bg-green/80 hover:bg-green/90 active:bg-green text-white px-4 py-1 rounded-md disabled:bg-green/70"
        >
          {response.fetching ? 'Confirming...' : 'Confirm'}
        </Button>
      </>
    )
  } else if (!verification.data?.email) {
    body = (
      <p className="text-red-600">
        Invalid verification link.
      </p>
    )
  } else {p
    body = (
      <p className="text-black">
        Unable to confirm email{' '}
        <a
          href={`mailto:${verification.data.email}`}
          className="text-teal-green/80 underline hover:text-teal-green"
        >
          {verification.data.email}
        </a>{' '}
        because it is already confirmed.
      </p>
    )
  }

  return (
    <div className="flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
        <h1 className="text-2xl font-semibold text-black mb-6 text-center">
          Confirm Email Address
        </h1>
        {body}
      </div>
    </div>
  )
}
